::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
  font-style: italic;
}

@font-face {
  font-family: GothamBold;
  src: url("./Fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: GothamBook;
  src: url("./Fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: GothamBoldIta;
  src: url("./Fonts/Gotham-BoldIta.otf") format("opentype");
}

@font-face {
  font-family: GothamBookIta;
  src: url("./Fonts/Gotham-BookIta.otf") format("opentype");
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes transitionOut {
  from {
    opacity: 1;
    transform: rotateX(-10deg);
  }
  to {
    opacity: 0;
    transform: rotateX(0);
  }
}

body,
html {
  margin: 0;
  height: 100vh;
  width: 100vw;
  font-family: "GothamBook";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: "GothamBold";
  margin-top: 30px;
}

nav {
  display: flex;
  list-style-type: none;
  gap: 20px;
  margin-right: 50px;
  z-index: 3;
  font-size: 18px;
  font-family: "GothamBook";
}

.main {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  animation: transitionIn 0.75s;
}

.footer {
  padding: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.line {
  border-right: 1px solid black;
}

.name {
  margin-left: 50px;
  font-weight: lighter;
  letter-spacing: 1px;
  font-family: "GothamBold";
  font-kerning: none;
}

.project--image {
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 0.6s ease-out;
  animation: transitionIn 1.5s;
}

.project--Image2 {
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: all 0.6s ease-out;
  animation: transitionIn 0.75s;
}

.imageBox {
  object-fit: contain;
  cursor: pointer;
  animation: transitionIn 1s;
  position: relative;
  font-size: 0;
  line-height: 0;
}

.projects--container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: 35px;
  animation: transitionIn 0.75s;
  line-height: 1.6;
  letter-spacing: 0.2px;
}

.projects--container--wrap {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.bottom {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: block;
  -webkit-transition: opacity 0.7s;
  -moz-transition: opacity 0.7s;
  -ms-transition: opacity 0.7s;
  -o-transition: opacity 0.7s;
  transition: opacity 0.7s;
}

.top {
  width: 100%;
  display: block;
}

.imageBox:hover .bottom {
  opacity: 0;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

li:hover {
  transform: scale(1.11);
}

li {
  transition: transform 0.2s; /* Animation */
}

.projectPage {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  background: white;
  overflow: scroll;
  height: 100vh;
  box-shadow: 0 3px 8px rgb(0 0 0 / 0.2);
  width: 100%;
  background: transparent;
  animation: transitionIn 0.65s;
}

.projectPage::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

.sideBar--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 2%;
}

.project--container {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  max-width: 850px;
  min-width: 300px;
  background-color: white;
}

.project--header {
  font-family: "GothamBold";
  text-align: left;
  margin-right: 30px;
  line-height: 1.1;
}

.sideImage {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  margin: 0;
}

.sidebarA {
  margin-top: 5%;
  margin-bottom: 7%;
  display: "inline-block";
}

.sidebarB {
  display: "inline-block";
  margin-bottom: 7%;
}

.sideP {
  margin-bottom: 7%;
  text-align: left;
  margin-top: 0;
}

.closeSide {
  font-size: 25px;
  cursor: pointer;
}

.closeSide:hover {
  transform: scale(1.1);
  line-height: 1;
}

.about {
  display: flex;
  gap: 60px;
  padding: 10px 60px;
  width: 100%;
  flex-wrap: wrap-reverse;
  animation: transitionIn 0.75s;
}

.profilePic {
  width: 450px;
}

.aboutRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  font-family: "GothamBold";
}

.aboutBody {
  line-height: 1.6;
  text-align: left;
  margin-bottom: 20px;
  margin-top: -1%;
  font-family: "GothamBook";
}

.aboutLogo {
  height: 45px;
}

.aboutLogo:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.contactInfo {
  flex-wrap: wrap;
}

.submitBtn {
  width: 75px;
  background-color: black;
  color: white;
  font-family: "GothamBook";
  font-style: italic;
  font-weight: bold;
}

.submitBtn:hover {
  cursor: pointer;
}

input,
textarea {
  padding: 10px;
  background-color: #d3d5d6;
  border: none;
  font-family: "Gotham";
}

.paragraph2 {
  margin-bottom: 7%;
}

@media screen and (max-width: 550px) {
  .header {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 25px;
    gap: 15px;
  }
  .name {
    margin: 0;
    font-size: 30px;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
  }
  nav {
    margin-left: 59.3px;
  }
  .project--container {
    padding: 0 20px;
  }
  .contactInfo {
    flex-direction: column;
  }

  .about {
    overflow-x: hidden;
    padding: 25px;
    justify-content: center;
  }

  .profilePic {
    width: 330px;
  }
  .closeSide {
    position: fixed;
    margin-left: 90%;
  }

  .linka {
    margin-top: -3%;
    margin-bottom: 10%;
  }
}
